import React, { createContext, useState } from "react";
import { Calls } from "../utils/call";
import { api } from "../urls";
import axios from "axios";

const LocationContext = createContext();

const LocationProvider = ({ children }) => {
  const [latLong, setLatLong] = useState({});
  const [address, setAddress] = useState("");
  const [bestRestaurantList, setBestRestaurantList] = useState([]);
  const [nearRestaurantList, setNearRestaurantList] = useState([]);

  const getCurrentAddress = async (lat, long) => {
    Calls.loader(true)
    try{
      const res = await axios.get(
        `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${lat}&lon=${long}`
      );
      if(res.data){
        setLatLong({lat:Number(lat),long:Number(long)})
        console.log("address", res.data.address);
        let data = res.data.address;
        bestRetaurantSection(Number(lat),Number(long))
        nearRestaurant(Number(lat),Number(long))
        setAddress(data.postcode + ", " + data.city + ", " + data.state);
      }
    }catch(err){
      
      console.log(err);
      
    }
  
  };
   const getLiveCurrentAddress = () => {
    Calls.loader(true)
  return   new Promise((resolve, reject) => {
      if (navigator?.geolocation) {
        navigator.geolocation.getCurrentPosition(async (position) => {
          try {
            setLatLong({lat:position.coords.latitude,long:position.coords.longitude})
            bestRetaurantSection(position.coords.latitude,position.coords.longitude)
            nearRestaurant(position.coords.latitude,position.coords.longitude)
            const res = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${position.coords.latitude}&lon=${position.coords.longitude}`);
            resolve(res.data.address);
            setAddress(res.data.address.postcode + ", " + res.data.address.city + ", " + res.data.address.state);
          } catch (error) {
            Calls.loader(false)
            reject(error);
          }
        }, (error) => {
          Calls.loader(false)
          reject(error);
        });
      } else {
        
        reject(new Error("Geolocation not supported"));
      }
    });
  };
  const nearRestaurant = async (latitude, longitude) => {
    // setLongLat({ latitude, longitude });
    const res = await Calls.requestPost(api.getRestaurantsNearMe, {
      latitude,
      longitude,
    });
    if (res.data) {
      setNearRestaurantList(res.data?.data);
    } else {
      setNearRestaurantList([]);
    }
  };
  const bestRetaurantSection = async (latitude, longitude) => {
    const res = await Calls.requestPost(api.getRestaurantsByLevel2Category, {
      latitude,
      longitude,
    });
    if (res.data) {
      setBestRestaurantList(res.data?.data);
    } else {
      setBestRestaurantList([]);
    }
  };
  

  return (
    <LocationContext.Provider
      value={{ latLong, getCurrentAddress, setLatLong, address, setAddress,getLiveCurrentAddress,bestRestaurantList,nearRestaurantList,setNearRestaurantList}}
    >
      {children}
    </LocationContext.Provider>
  );
};

export { LocationContext, LocationProvider };
